<template>
  <div class="Container">
    <Parallax :images="ParallaxImages()" />
    <div class="Container__Column">
      <div class="Container_Blur" />
      <div class="Container__Top">
        <div>
          <p class="Container__TitleText">
            {{ firstTime ? 'ENTER ' : 'RESET ' }} YOUR PASSWORD
          </p>
        </div>
        <div class="Fields">
          <div class="Container__InterDiv">
            <LoginField
              :placeholder="'Your password'"
              :input-type="'password'"
              @pwdChanged="UpdatePwd($event, 'password')"
            />
            <!-- <input
              v-model="password"
              class="Container__Field"
              :type="passwordVisible ? 'text' : 'password'"
            /> -->
            <button
              @click="passwordVisible = !passwordVisible"
              class="LoginField_Button"
            >
              <div
                :class="[
                  !passwordVisible
                    ? 'LoginField_Icon LoginField_ViewIcon'
                    : 'LoginField_Icon LoginField_HideIcon'
                ]"
              />
            </button>
          </div>
          <div class="Container__InterDiv">
            <LoginField
              :placeholder="'Confirm password'"
              :input-type="'password'"
              @pwdChanged="UpdatePwd($event, 'password')"
            />
          </div>
          <!-- <input v-model="passwordConfirm" class="Container__Field" :type="passwordVisible ? 'text' : 'password'" :style="{border: password === passwordConfirm ? '' : 'red solid 1px' }"/> -->
        </div>
      </div>
      <!-- <div class="ProgressBar">
        <div class="ProgressBar__BG">
          <div
            class="ProgressBar__Progress"
            :style="{
              width: +GetValue() + '%',
              background:
                strengthScore < passwordRules.length ? 'darkred' : 'green'
            }"
          />
        </div>
      </div> -->
      <div class="Rules">
        <div v-for="(rule, i) in passwordRules" :key="i">
          <p
            :style="{ color: !rule.fn(password) ? 'black' : 'green' }"
            class="Rule"
          >
            * {{ rule.title }}
          </p>
        </div>
      </div>
      <CTA
        class="Container__Btn"
        label="Validate"
        @clickButton="Validate"
        :color="'#d7321e'"
      />
    </div>
  </div>
</template>

<script>
import api from '@/services/api'
import { EventBus } from '@/event-bus.js'
import token from '@/mixins/token'
import LoginField from '../login/components/LoginField'
import CTA from '@/components/button/CTA'
import Parallax from '@/components/parallax/Parallax.vue'

const zxcvbn = require('zxcvbn')

export default {
  props: {
    firstTime: {
      type: Boolean,
      default: false
    }
  },
  mixins: [token],
  components: {
    LoginField,
    CTA,
    Parallax
  },
  data () {
    return {
      password: '',
      passwordConfirm: '',
      passwordVisible: false,
      wrongConfirm: false,
      passwordRules: [
        {
          title: 'one lower character',
          display: true,
          fn: scheme => new RegExp('^(?=.*[a-z])').test(scheme)
        },
        {
          title: 'one upper character',
          display: true,
          fn: scheme => new RegExp('^(?=.*[A-Z])').test(scheme)
        },
        {
          title: 'one digit character',
          display: true,
          fn: scheme => new RegExp('^(?=.*[0-9])').test(scheme)
        },
        {
          title: '12 characters',
          display: true,
          fn: scheme => scheme.length >= 12
        },
        {
          title: 'one special character (!@#$%^&*)',
          display: true,
          fn: scheme => new RegExp('^(?=.*[!@#$%^&*])').test(scheme)
        },
        {
          title: 'passwords must match',
          display: true,
          fn: () =>
            this.password.length > 0 && this.password === this.passwordConfirm
        },
        {
          title: 'password must meet complexity requirements',
          display: true,
          fn: () => zxcvbn(this.password).score >= 4
        }
      ],
      strengthScore: 0
    }
  },
  methods: {
    ParallaxImages () {
      const result = []
      result.push({ src: 'Exterior_03.png', force: 0 })
      result.push({ src: 'Exterior_02.png', force: 10 })
      result.push({ src: 'Exterior_01.png', force: 30 })
      return result
    },
    ParallaxAnimations () {
      const result = []
      result.push({
        src: ['Birds_01.png', 'Birds_02.png', 'Birds_03.png'],
        force: -20
      })
      return result
    },
    UpdatePwd (value, type) {
      this[type] = value
      if (type === 'password') {
        this.strengthScore = 0
        this.passwordRules.find(rule => {
          if (rule.fn(value)) {
            this.strengthScore += 1
          }
        })
      } else {
        if (this.password !== this.passwordConfirm) this.wrongConfirm = true
        else this.wrongConfirm = false
      }
    },
    GetValue () {
      return (this.strengthScore / this.passwordRules.length) * 100
    },
    async Validate () {
      if (this.password !== this.passwordConfirm) {
        this.wrongConfirm = true
      } else {
        this.wrongConfirm = false
        const status = {
          '2fa-unconfirmed': { name: 'handle2fa' },
          '2fa-enabled': { name: 'twofa' },
          success: { name: 'login' }
        }
        try {
          const ans = await api.auth
            .setPassword(
              this.$route.params.token,
              this.password,
              this.firstTime
            )
            .then(ans => {
              // console.log(ans.data)
              return ans.data
            })
            .catch(e => {
              const res = JSON.parse(e.request.response)
              // console.log(res)
              return Object.keys(status).indexOf(res.name) !== -1
                ? res
                : new Error(e.request.response)
            })
          if (ans instanceof Error) throw new Error(ans.message)
          if (status[ans.name] !== undefined) {
            this.setToken(ans.data.token)
            this.$router.push(status[ans.name])
          } else throw new Error(ans)
          // this.$router.push({ path: 'login' });
        } catch (e) {
          const error = JSON.parse(e.message)
          console.error(error)
          EventBus.$emit('notify', {
            type: 'error',
            message: error.description,
            hidden: true
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/main.scss';

.Container__Image_Bg {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.Container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.Container__Column {
  position: relative;
  box-sizing: border-box;
  padding: 2rem 1.5rem;
  height: 450px;
  width: 365px;
  border-radius: 2.5rem;
  background-color: rgba(255, 255, 255, 0.5);
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-height: 610px) and (orientation: landscape) {
    height: 90%;
    padding: 0.5rem 1.5rem;
  }
  @media only screen and (max-width: 400px) {
    width: 90%;
  }
}

.Container_Blur {
  border-radius: 2.5rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  backdrop-filter: blur(10px);
}

.Container__TitleText {
  font-family: 'Gotham Bold';
  @include fluid-type($min_width, $max_width, $min_font * 1.5, $max_font * 1);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 2rem;
  @media only screen and (max-height: 610px) and (orientation: landscape) {
    margin-bottom: 0.5rem;
  }
}

.Fields {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Container__InterDiv {
  height: 50px;
  &:first-child {
    margin-bottom: 10px;
  }
}

.Rule {
  font-family: 'Gotham Book';
  @include fluid-type($min_width, $max_width, $min_font * 1, $max_font * 0.7);
  margin-bottom: 0.3rem;
}

.Container__Btn {
  height: 12%;
}

.ProgressBar {
  margin: auto;
  height: 2px;
  width: 100%;
  &__BG {
    background: lightgray;
    width: 100%;
    height: 100%;
    position: relative;
  }
  &__Progress {
    position: absolute;
    height: 2px;
  }
}

.InputField {
  margin: auto;
  flex: 1;
  position: relative;
}

.LoginField_Button {
  position: absolute;
  margin: auto;
  right: 1em;
  height: 20px;
  width: 20px;
}

.LoginField_Icon {
  position: absolute;
  top: 0px;
  left: 0px;
  background: rgb(90, 89, 89);
  width: 100%;
  height: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

.LoginField_ViewIcon {
  width: 100%;
  height: 15px;
  mask-image: url('../../assets/icons/eye.svg');
}

.LoginField_HideIcon {
  width: 100%;
  height: 15px;
  mask-image: url('../../assets/icons/eye-off.svg');
}
</style>
